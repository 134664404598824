<template>
    <div class="allcontant">
        <div class="head">
            <div class="headLeft">
                <img style="width:80px;max-height:50px" v-if='!domain' src="https://wlzj-platform.posedu.com.cn/manage/uploads/20210818/1bij1mf9tft4c8dkwmhb928ccb9q909g.png">
                <img style="width:500px;max-height:60px" v-else :src="infoList.doorInfo.logo">
                <div v-if='!domain' style='max-height: 50px;margin-top:26px'>
                    <h1 style="margin-bottom: -5px;margin-top: -30px;letter-spacing: 2px;margin-left: 10px;">位来教育大赛展示平台</h1>
                    <p style="margin-bottom: 0;font-size:11px">Wei Lai Education Competition Display Platform</p>
                </div>
                <div v-else style='max-height: 50px;margin-top:26px'>
                    <h1 class='h1class' style="margin-bottom: -5px;margin-top: -30px;letter-spacing: 2px;margin-left: 10px;" v-html='infoList.doorInfo.name'></h1>
                    <!-- <p  style="margin-bottom: 0;font-size:11px">Wei Lai Education Competition Display Platform</p> -->
                </div>
            </div>
            <div class="headRight" style="position: relative;">
                <router-link :to='{path:"/home"}' style='margin-left:20px'>首页</router-link>
                <router-link :to='{path:"/notice",query:{info:domain}}' style='margin-left:30px'>赛事通知</router-link>
                <!-- <router-link  :to='{path:"/matchNew",query:{info:domain}}'  style='margin-left:30px'>报名通道</router-link> -->
                <a @click="goAnchor('#matchnews')" style='margin-left:30px'>报名通道</a>
                <a href='https://match.posedu.com.cn/mms/#/login' target='_blank' style='margin-left:30px'>省级管理员</a>

            </div>
        </div>
        <div @click="back" class="back">返回</div>
        <div style="background-color: rgb(255, 255, 255);
    width: 65%;
    margin: 0 auto;min-height: calc(100vh - 140px);padding: 0 20px;">
            <h2 style="text-align:center;margin-top: 0;
    padding-top: 87px;">{{infodata.title}}</h2>
            <div style="
    margin: 0 auto;
    padding-top: 50px;white-space:pre-wrap" v-html="infodata.content">
            </div>
        </div>
        <div class="footer">
            <div style="    margin-left: -147px;
    margin-top: 24px;">
                <p v-if='domain== "gansu"'>版权所有:甘肃省教育厅</p>
                <p v-if='domain== "gansu"'>技术支持:北京位来教育科技有限公司 CopyRight 2021</p>
                <p v-else>版权所有:北京位来教育科技有限公司 CopyRight 2021</p>
                <p style="
    text-align: left;
">备案号 :京ICP备20011457号-1</p>


                <!-- <p>商务合作：400-4333-222</p>
                <p>公司地址：北京市海淀区</p> -->
            </div>
            <div style="    margin-top: 10px;">
                <img src="../assets/二维码.jpg" style="width:80px;height:80px">
                <p style="margin-top: 0;">官方公众号</p>
            </div>
        </div>
    </div>
</template>

<script>
    // import { getDataInfo } from "@/api/notice";
    export default {
        data() {
            return {
                params: {
                    id: ''
                },
                infodata: null
            }
        },
        mounted() {
            this.params.id = this.$route.query.id
            this.getList()
        },
        methods: {
            getList() {
                this.$http({
                    url: `https://api.match.posedu.com.cn/match/dynamic/matchDynamicInfo/${this.params.id}`,
                    method: 'get',
                }).then(res => {
                    this.infodata = res.data.data
                })
                this.$http({
                    url: `https://api.match.posedu.com.cn/match/match/info/${this.params.id}`,
                    method: 'get',
                }).then(res => {
                    this.matchInfo = res.data.data
                })
            },
            back() {
                this.$router.go(-1)
            },
            //锚点跳转
            goAnchor() {
                this.$router.push({path: "/", query: {mao: 1}})
                // var anchor = this.$el.querySelector(selector) // 参数为要跳转到的元素id,是(#id)
                // document.body.scrollTop = anchor.offsetTop; // chrome
                // document.documentElement.scrollTop = anchor.offsetTop; // firefox
            },
        }
    }
</script>

<style scoped>
    .allcontant {
        height: auto;
        min-height: calc(100vh - 140px);
        /* background-color: #f1f1f1; */
        margin-bottom: -30px;
        /* margin-top: 70px; */
    }

    .back {
        position: fixed;
        left: 377px;
        top: 90px;
        cursor: pointer;
    }

    .phone {
        margin-top: 40px;
    }

    @media screen and (max-width: 900px) {
        .allcontant > div {
            width: 100% !important;
            margin-top: 30px !important;

        }
    }

</style>
